<template>

  <!-- Лента пользователя -->
  <v-container id="statusRequestForTransporter" class="h-100">

    <div class="back-button text-right">
      <v-btn icon @click="goBack">
        <v-icon color="grey darken-2">mdi-chevron-down</v-icon>
      </v-btn>
    </div>

    <div class="pb-3 mb-3">
      <div class="top-text my-3">
        <h1 class="title is-1 thin black--text">Что</h1>
        <h1 class="title is-1 black--text">везём?</h1>
      </div>
    </div>

    <div class="remaining-space">
      <p class="title is-4 mb-0">
        Вы успешно заполнили заявку на статус перевозчика. Мы её одобрим или позвоним уточнить некоторую информацию.
        <br><br>Отследить статус заявки вы сможете на главной странице, нажав на нужный раздел. Если есть вопросы, вы можете задать их по указанным контактам (при нажатии на кнопку).
        <br><br>Благодарим вас за ожидание и желание присоединиться к проекту!
      </p>
    </div>

    <div class="pa-3 pb-0" style="text-align: center;">
      <p class="buttons mb-0 flex justify-center align-center">
        <b-button @click="goToInfoPage" class="w-100" type="is-info is-light">
          Контакты
        </b-button>
      </p>
      <p class="buttons mb-0 flex justify-center align-center">
        <b-button @click="goToMain" class="w-100" type="is-success is-light">
          Понятно
        </b-button>
      </p>
    </div>

    <FullScreenLoader :show="isLoading" />

  </v-container>

</template>

<script>
export default {
  name: 'CancellationOfOrder',
  data() {
    return {
      isLoading: false
    };
  },
  created() {
    this.$store.dispatch('GET_ACTIVE_ORDER');
  },
  computed: {
  },
  methods: {
    goBack() {
      this.$router.push('/activity-feed');
    },
    goToMain() {
      this.$router.push("/activity-feed");
    },
    goToInfoPage() {
      this.$router.push("/about-program");
    }
  },
};
</script>

<style scoped>
#statusRequestForTransporter .title.is-4 {
  font-size: 1.15rem!important;
}


.block-content img {
  max-width: 100%;
  height: auto;
}

.title.is-1 {
  font-size: 4.5rem !important;
}

.title.is-1.thin {
  font-weight: 300;
}

#statusRequestForTransporter.container {
  display: flex;
  flex-direction: column;
}

.remaining-space {
  flex-grow: 1;
}

.title.is-4 {
  margin-bottom: 0px !important;
  line-height: normal !important;
}

</style>
